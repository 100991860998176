import styled from "@emotion/styled";
import { Breakpoints } from "../constants";
import shareIcon from "../assets/images/share-icon.svg";
import play from "../assets/images/play.svg";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import copy from "../assets/images/copy.png";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { trackEvent } from "./GoogleAnalyticsLable";

const Wrapper = styled.div`
  position: relative;
  width: 416px;
  height: 236px;
  cursor: pointer;
  overflow: hidden;

  @media only screen and (max-width: ${Breakpoints.MED}) {
    width: 333px;
    height: 189px;
  }
  .thumb {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  :hover {
    color: #000;
    .thumb {
      transform: scale(1.2);
    }
    .gray-back {
      transform: translateY(0);
    }
    .share {
      filter: invert(1);
    }
    .play-button {
      display: block;
    }
    .divider {
      background-color: #000;
    }
  }
  color: #fff;
  .thumb {
    transition: transform 0.3s ease-in-out;
  }
  .info {
    position: absolute;
    width: -webkit-fill-available;
    bottom: 14px;
    z-index: 10;
    display: flex;
    padding: 0 5%;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 22px;
    gap: 20px;
    @media only screen and (max-width: ${Breakpoints.SMALL}) {
      font-size: 20px;
      flex-direction: row;

      div:nth-child(3) {
        font-size: 16px;
      }
    }
  }
  .info-sub {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .share {
    width: 22px;
    height: 24px;
  }
  .gray-back {
    position: absolute;
    height: 100%;
    width: -webkit-fill-available;
    background-color: rgba(218, 217, 217, 0.6);
    z-index: 5;
    left: 0;
    top: 0;
    transform: translateY(97%);
    transition: transform 0.3s ease-in-out;
  }
  .play-button {
    position: absolute;
    z-index: 25;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 204px;
    height: 204px;
    @media only screen and (max-width: ${Breakpoints.SMALL}) {
      height: 150px;
      width: 150px;
    }
  }
  .divider {
    height: 20px;
    width: 3px;
    background-color: #fff;
  }
`;

const BottomSheet = styled.div`
  padding: 30px;
  display: flex;
  gap: 4%;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: ${Breakpoints.SMALL}) {
    gap: 10px;
  }
  button:hover {
    transform: scale(1.2);
  }
  img:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const TourThumbnail = ({ projectInfo }) => {
  const [share, setShare] = useState(false);

  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setShare(false);
      })
      .catch((err) => {
        console.error(`Error copying text to clipboard: ${err}`);
      });
  };

  function renderShare() {
    return (
      <Drawer
        anchor={"bottom"}
        open={!!share}
        onClose={() => {
          setShare(false);
        }}
      >
        <BottomSheet>
          <WhatsappShareButton url={share}>
            <WhatsappIcon size={50} round />
          </WhatsappShareButton>
          <FacebookShareButton url={share}>
            <FacebookIcon size={50} round />
          </FacebookShareButton>

          <TwitterShareButton url={share}>
            <TwitterIcon size={50} round />
          </TwitterShareButton>

          <EmailShareButton url={share}>
            <EmailIcon size={50} round />
          </EmailShareButton>

          <LinkedinShareButton url={share}>
            <LinkedinIcon size={50} round />
          </LinkedinShareButton>

          <PinterestShareButton url={share} media={share}>
            <PinterestIcon size={50} round />
          </PinterestShareButton>

          <RedditShareButton url={share}>
            <RedditIcon size={50} round />
          </RedditShareButton>

          <TelegramShareButton url={share}>
            <TelegramIcon size={50} round />
          </TelegramShareButton>
          <img
            src={copy}
            alt="copy"
            height="45px"
            width="45px"
            onClick={() => {
              handleCopy(projectInfo.projectLink);
            }}
          />
        </BottomSheet>
      </Drawer>
    );
  }
  return (
    <Wrapper>
      <img className="thumb" src={projectInfo.thumbnail} alt={"Thumbnail"} />
      <div className="info">
        <div className="info-sub">
          <div>{projectInfo.projectName}</div>
          <div className="divider"></div>
          <div>{projectInfo.location}</div>
        </div>
        <img
          className="share"
          src={shareIcon}
          alt="share"
          onClick={() => {
            setShare(projectInfo.projectLink);
          }}
        />
      </div>
      <div className="gray-back"></div>
      <a
        href={projectInfo.projectLink}
        onClick={() => trackEvent("Tour", "click", projectInfo?.projectName)}
        target="_blank"
        rel="noreferrer"
      >
        <img className="play-button" src={play} alt="play" />
      </a>
      {renderShare()}
    </Wrapper>
  );
};

export default TourThumbnail;
