import React from "react";
import "./ourService.css";

const OurService = () => {
  return (
    <>
      <div className="services-main">
        <div className="our-service-main-div">
          <p className="font-hr">Our Services</p>
          <div>
            <div className="content font-inter">
              <span>
                At True Dimensions, we specialize in creating captivating and
                lifelike virtual tours that transport your audience into a world
                of boundless possibilities. Whether you're in the hospitality
                industry, education sector, manufacturing, retail, or real
                estate, we empower your business to showcase its unique essence
                like never before. Our cutting-edge virtual tour service
                transforms mere images into interactive, three-dimensional
                experiences, breathing life into spaces and amplifying
                engagement.
                <br></br>
                <br></br>
                Imagine inviting your potential guests to explore the luxurious
                suites of your hotel or the tranquil landscapes of your resort
                from the comfort of their homes. Envision students embarking on
                a virtual journey through your educational institute, gaining an
                authentic sense of your campus and facilities. Picture your
                showroom or studio coming to life in the digital realm, allowing
                customers to browse and interact with your offerings as if they
                were physically present.
              </span>
            </div>
          </div>
        </div>
        <div className="benefits">
          <p>Why True Dimensions ?</p>
          <span className="commitment">
            Our commitment to precision and excellence sets us apart. With True
            Dimensions, you'll receive:
          </span>
          <ul className="ul">
            <li>
              <b>Interactivity Redefined:</b> Engage your audience with
              interactive elements, allowing them to navigate, zoom, and explore
              every nook and cranny, enhancing their overall experience.
            </li>
            <li>
              <b>Seamless Integration:</b> Easily embed our virtual tours on
              your website or share them across various platforms, seamlessly
              integrating them into your digital presence.
            </li>
            <li>
              <b>Customized Solutions:</b> We understand that each business is
              unique. Our team works closely with you to tailor virtual tours
              that perfectly reflect your brand and objectives.
            </li>
            <li>
              <b>Innovative Technology:</b> Leveraging the latest advancements
              in virtual reality and 3D imaging, we stay at the forefront of
              technology to deliver the best possible experience.
            </li>
          </ul>
          <span className="why-us">
            <b>Diverse Applications:</b> From hotels to industries, from
            showrooms to educational institutions, our virtual tours cater to a
            wide range of domains, transforming your vision into a captivating
            reality.
            <br></br>
            <br></br>
            At True Dimensions, we transcend the limitations of physical
            boundaries, offering a bridge between imagination and reality. Our
            virtual tours transcend distances, enabling your audience to step
            into your world, regardless of their location. Join us on a journey
            to redefine exploration – where every click is an adventure, every
            step a revelation, and every experience, True Dimensions.
          </span>
        </div>
      </div>
    </>
  );
};

export default OurService;
