import { css, Global } from "@emotion/react";

const GlobalStyle = () => {
  return (
    <Global
      styles={(props) => css`
        :root {
          --primary-gradient: linear-gradient(180deg, #0037ff 0%, #28aae1 100%);
          --primary: #0037ff;
        }
        body {
          margin: 0;
          padding: 0;
        }
        .font-roboto {
          font-family: "Roboto", sans-serif;
        }
        .font-hr {
          font-family: "Helvetica Rounded", sans-serif;
        }
        .font-inter {
          font-family: "Inter", sans-serif;
        }
      `}
    />
  );
};

export default GlobalStyle;
