import React from "react";
import "./aboutus.css";

const AboutUs = () => {
  return (
    <>
      <div className="about-us-main font-hr">
        <div className="main">
          <p>About Us</p>
          <span>
            True Dimensions is a dynamic and innovative team comprised of
            seasoned professionals with a cumulative technical expertise
            exceeding 8 years. Nestled in the vibrant city of Hubli, Karnataka,
            our company has emerged as a trailblazer in the realm of virtual
            tours. With a proven track record of catering to premium clientele,
            we take immense pride in our achievements over the past year. Our
            journey has been defined by pushing the boundaries of visual
            storytelling, seamlessly melding technology and creativity to craft
            immersive virtual experiences. As we continue to evolve, we remain
            steadfast in our commitment to delivering unparalleled virtual tour
            services that captivate, engage, and astonish.
          </span>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
