import React, { useEffect, useState } from "react";

export function CalculateAmount({ data }) {
  const [grossTotal, setGrossTotal] = useState(0);
  useEffect(() => {
    setGrossTotal(0);
    if (data) {
      const openAreaPano =
        data?.openArea <= 5000
          ? 1
          : data?.openArea > 5000
          ? data?.openArea / 5000
          : 1;

      const builtUpPano =
        data?.builtUpArea <= 500
          ? 1
          : data?.builtUpArea > 500
          ? data?.builtUpArea / 500
          : 1;

      const infoCards = data?.premiumFeatures?.infoCards * 150; //-----------------Pop up cards cost
      const totalPanos = openAreaPano + builtUpPano;
      const panoAmount = totalPanos * 500; //--------------------------------------Pano's total cost
      const infoCardsAmount = infoCards;
      const albumAmont = (data?.premiumFeatures?.photoAlbum - 1) * 500; //---------Album cost
      const popUpImagesAmount = data?.popUpImagePriced ? 1000 : 0; //--------------Pop Up images cost
      const onScreenVideoAmount =
        data?.premiumFeatures?.onScreenVideo > 2 ? 1000 : 0; //--------------------On screen videos cost
      const panoVideoAmount = data?.premiumFeatures?.panoVideo * 500; //-----------360 videos cost
      const dynamicDataAmount = data?.dymanicData ? 2000 : 0; //-------------------Dynamic data cost
      const cgiAmount =
        data?.premiumFeatures?.cgi > 2 ? 250 * data?.premiumFeatures?.cgi : 0; //--CGI costing
      const customHotspotsAmount = data?.branding?.customHotspotPriced
        ? 1000
        : 0; //--------------------------------------------------------------------Brand hotspot cost
      const customColorAmount = data?.branding?.brandColor ? 1000 : 0; //----------Custom color pallete cost
      const customLoadingScreenAmount = data?.branding?.customLoading
        ? 1000
        : 0; //--------Custom loading screen amount
      const customAmbientSoundAmount = data?.branding?.ambientSounds ? 1000 : 0; //Custom ambient sounds
      const totalMB = Math.ceil((totalPanos * 20) / 1024);

      const hostingAmount = data?.truedHosting ? totalMB * 50 : 0; //---------------Hosting total

      /**
       * This function helps in switching location cases
       * @returns
       */
      const generateLocationCost = () => {
        const totalDays = Math.ceil(totalPanos / 100);
        const opsCost = 3800 * totalDays;
        const location = data?.location;
        switch (location) {
          case "Karnataka (Hubli-Dharwad)":
            return 0;
          case "Karnataka (Outside Hubli-Dharwad)":
            return 4000 + opsCost;
          case "Telangana":
          case "kerala":
            return 8000 + opsCost;
          case "Andhara Pradesh":
          case "Tamil Nadu":
            return 10000 + opsCost;
          case "Goa":
            return 4000 + opsCost;
          case "Maharashtra":
            return 5000 + opsCost;
          case "Other":
            return 20000 + opsCost;
          default:
            break;
        }
      };
      const travelExpense = generateLocationCost();
      const grossTotal =
        infoCardsAmount +
        panoAmount +
        albumAmont +
        popUpImagesAmount +
        onScreenVideoAmount +
        panoVideoAmount +
        dynamicDataAmount +
        customHotspotsAmount +
        customColorAmount +
        customLoadingScreenAmount +
        customAmbientSoundAmount +
        cgiAmount +
        hostingAmount * 12;

      const netAmount = grossTotal * 0.15 + grossTotal;
      const gstAmount = netAmount * 0.18 + netAmount;
      const totalGross = data?.gst
        ? gstAmount + travelExpense
        : netAmount + travelExpense;

      setGrossTotal(totalGross);
    }
  }, [data]);
  return (
    <>
      <div>Grand Total - {Math.ceil(grossTotal)}</div>
    </>
  );
}
