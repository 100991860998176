import React from "react";
import styled from "@emotion/styled";
import { Breakpoints } from "../../constants";

const IframeWrapper = styled.div`
  width: 100%;
  height: 720px;
  @media (max-width: ${Breakpoints.SMALL}) {
    width: 100%;
    height: 35vh;
  }
`;

const VirtualTour = () => {
  return (
    <IframeWrapper>
      <iframe
        title={`True Dimensions`}
        width="100%"
        height="100%"
        src={"https://truedimensions.tech/virtual-tour/true-iframe/"}
        frameBorder="0"
        style={{ pointerEvents: "none" }}
      ></iframe>
    </IframeWrapper>
  );
};

export default VirtualTour;
