import styled from "@emotion/styled";
import { Sectors, Breakpoints, Tours } from "../constants";
import { useState } from "react";
import TourThumbnail from "./TourThumbnail";
import { trackEvent } from "./GoogleAnalyticsLable";

const Wrapper = styled.div`
  font-weight: 700;
  margin-bottom: 50px;
  @media only screen and (max-width: ${Breakpoints.MED}) {
    padding: 0;
  }
  .heading {
    font-size: 48px;
    border-bottom: 3px solid var(--primary);
    line-height: 56px;
    width: max-content;
    margin: auto;
    margin-bottom: 30px;
    @media only screen and (max-width: ${Breakpoints.MED}) {
      font-size: 30px;
    }
  }
  .sectors {
    display: flex;
    justify-content: center;
    gap: 5%;
    align-items: center;
    font: 24px;
    line-height: 28.25px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    div:after {
      display: block;
      content: "";
      border-bottom: 3px solid var(--primary);
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
    div:hover:after,
    .sector-border:after {
      transform: scaleX(1);
    }
    div.fromLeft:after {
      transform-origin: 0% 50%;
    }
    @media only screen and (max-width: ${Breakpoints.MED}) {
      padding: 0 5px;
    }
  }
  .sector {
    cursor: pointer;
  }
  .thumb-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: ${Breakpoints.MED}) {
    }
  }
`;
const OurWork = () => {
  const [selectedSec, setSelectedSec] = useState(0);

  function combineTours(arr) {
    const combinedTours = [];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].tours) {
        combinedTours.push(...arr[i].tours);
      }
    }

    return combinedTours;
  }

  return (
    <Wrapper>
      <div className="font-hr">
        <div className="heading">Our Work</div>
        <div className="sectors">
          {Sectors.map((sector, index) => (
            <div
              className={`sector ${
                selectedSec === index ? "fromLeft sector-border" : ""
              }`}
              key={index}
              onClick={() => {
                setSelectedSec(index);
                trackEvent("Our-work", "click", sector);
              }}
            >
              {sector}
            </div>
          ))}
        </div>
      </div>
      <div className="thumb-container">
        {selectedSec !== 0
          ? Tours[selectedSec]?.tours?.map((tour, index) => (
              <TourThumbnail key={index} projectInfo={tour} />
            ))
          : combineTours(Tours).map((tour, index) => (
              <TourThumbnail key={index} projectInfo={tour} />
            ))}
      </div>
    </Wrapper>
  );
};

export default OurWork;
