import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import Header from "./components/Header";
import Hero from "./components/HeroSection/Hero";
import VirtualTour from "./components/360Section/VirtualTour";
import OurWork from "./components/OurWork";
import TotalDeliveries from "./components/ProjectsCount/TotalDeliveries";
import Footer from "./components/Footer/Footer";
import OurService from "./components/OurServices/OurService";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs/AboutUs";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_KEY);

function App() {
  const navigate = useNavigate();
  const path = window.location.href.split("/").slice(-1)[0];
  // Redirect to "/home" on initial load if the path is "/"
  useEffect(() => {
    if (path === "" || path === "/") {
      navigate("/#home");
    } else {
      const target = document.querySelector(path);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [navigate, path]);

  return (
    <>
      <GlobalStyle />
      <Header />
      <section id="home">
        <Hero />
      </section>
      <section id="virtual-tour-section">
        <VirtualTour />
      </section>
      <section id="total-deliveries-section">
        <TotalDeliveries />
      </section>
      <section id="services">
        <OurService />
      </section>
      <section id="our-work">
        <OurWork />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <Footer />
    </>
  );
}

export default App;
