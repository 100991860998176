import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Breakpoints } from "../constants";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  CustomBrandingOptions,
  priceParams,
  ServiceLocations,
} from "../constants";
import { Button } from "@mui/material";
import { CalculateAmount } from "./CalculatorFunc";
import { auth, provider, db } from "../firebase";
import googleLogo from "../assets/images/google-sign-in.png";
import { signInWithPopup } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  @media only screen and (max-width: ${Breakpoints.SMALL}) {
    height: auto;
  }
`;

const Wrapper = styled.div`
  width: 75%;
  padding: 20px;
  box-shadow: 0 0 50px 20px rgba(0, 55, 255, 0.5);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${Breakpoints.SMALL}) {
    width: 90%;
    box-shadow: none;
    border-radius: 0;
  }

  h2 {
    text-align: center;
  }
  .label {
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 2px;
  }
  .text-cont {
    display: flex;
    gap: 20px;
    @media only screen and (max-width: ${Breakpoints.SMALL}) {
      flex-direction: column;
    }
  }
  .w-100 {
    width: 100%;
  }
  .flex {
    display: flex;
    align-items: center;
  }

  .check-cont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @media only screen and (max-width: ${Breakpoints.P800}) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .MuiListItemIcon-root {
    min-width: 0 !important;
  }
  .price-container {
    text-align: center;
    height: 30px;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
  }
  .btn-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  padding-right: 10px;
`;

const SignIn = styled.div`
  background: linear-gradient(180deg, #0037ff 0%, #28aae1 100%);
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Helvetica Rounded", sans-serif;
  width: 275px;
  margin: auto;
  margin-top: calc(50vh - 50px);
  gap: 20px;
  .logo {
    height: 30px;
    width: 30px;
  }
`;

const premiumCheckbox = {
  infoCards: false,
  photoAlbum: false,
  onScreenVideo: false,
  cgi: false,
  panoVideo: false,
  tourGuide: false,
};

const Calculator = () => {
  //Authentication
  const [loggedIn, setLoggedIn] = useState(false);
  const [authEmails, setAuthEmails] = useState([]);
  const [authError, setAuthError] = useState(false);

  //Calculation
  const [values, setValues] = useState(priceParams);
  const [premiumBooleans, setPremiumBooleans] = useState(premiumCheckbox);
  const [openAreaError, setOpenAreaError] = useState(false);
  const [builtAreaError, setBuiltAreaError] = useState(false);
  const [click, setClick] = useState(false);

  const handleSwitches = (currValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [currValue]: !prevValues[currValue],
    }));
  };

  const handlePremiumCheckbox = (currValue) => {
    setPremiumBooleans((prevValues) => ({
      ...prevValues,
      [currValue]: !prevValues[currValue],
    }));
    setValues((prevValues) => ({
      ...prevValues,
      premiumFeatures: {
        ...prevValues.premiumFeatures,
        [currValue]: "",
      },
    }));
  };

  const handleOpenAreaChange = (newValue) => {
    const test = /^\d*$/.test(newValue);

    if (!test) {
      return null;
    }
    if (newValue !== "") {
      newValue = Number(newValue);
    }
    if (newValue === 0 && values.builtUpArea === 0) {
      setOpenAreaError(true);
    } else {
      setOpenAreaError(false);
    }
    setValues((prevValues) => ({
      ...prevValues,
      openArea: newValue,
    }));
  };

  const handleBuiltAreaChange = (newValue) => {
    const test = /^\d*$/.test(newValue);

    if (!test) {
      return null;
    }
    if (newValue !== "") {
      newValue = Number(newValue);
    }
    if (newValue === 0 && values.openArea === 0) {
      setBuiltAreaError(true);
    } else {
      setBuiltAreaError(false);
    }
    setValues((prevValues) => ({
      ...prevValues,
      builtUpArea: newValue,
    }));
  };

  const handleReset = () => {
    setClick(false);
    setValues({ ...priceParams });
    setPremiumBooleans({ ...premiumCheckbox });
  };

  const inputHelperFunction = (newValue, min) => {
    if (min === 0) {
      return newValue > min ? newValue : "";
    } else if (newValue.toString().charAt(0) === min.toString()) {
      return newValue;
    }
    return newValue > min ? newValue : "";
  };

  const handleInputChange = (event, field, min) => {
    const newValue = event.target.value;
    const test = /^\d*$/.test(newValue);
    if (!test) {
      return null;
    }
    if (newValue === "") {
      setValues((prevValues) => ({
        ...prevValues,
        premiumFeatures: {
          ...prevValues.premiumFeatures,
          [field]: newValue,
        },
      }));
      return;
    }

    setValues((prevValues) => ({
      ...prevValues,
      premiumFeatures: {
        ...prevValues.premiumFeatures,
        [field]: inputHelperFunction(newValue, min),
      },
    }));
  };

  const handleResetDisable = () => {
    if (
      JSON.stringify(values) === JSON.stringify(priceParams) &&
      JSON.stringify(premiumCheckbox) === JSON.stringify(premiumBooleans)
    ) {
      return true;
    } else if (JSON.stringify(values) !== JSON.stringify(priceParams)) {
      return false;
    } else if (
      JSON.stringify(premiumCheckbox) !== JSON.stringify(premiumBooleans)
    ) {
      return false;
    }
    return true;
  };

  const handleBranding = (event, key) => {
    setValues((prevValues) => ({
      ...prevValues,
      branding: {
        ...prevValues.branding,
        [key]: !prevValues.branding[key],
      },
    }));
  };

  const areCorrespondingValuesEqual = (obj1, obj2) => {
    return Object.keys(obj1).every((key) => !!obj1[key] === !!obj2[key]);
  };

  const handleDataValidation = () => {
    if (
      !openAreaError &&
      !builtAreaError &&
      !!values.location &&
      (!!values.openArea || !!values.builtUpArea) &&
      areCorrespondingValuesEqual(premiumBooleans, values.premiumFeatures)
    ) {
      return false;
    }
    return true;
  };

  const handleCalculate = () => {
    setClick(true);
  };
  const handleLogin = async () => {
    await signInWithPopup(auth, provider);
    if (!authEmails.includes(loggedIn)) {
      setAuthError(true);
    }
  };

  async function fetchData() {
    let emails;
    const querySnapshot = await getDocs(collection(db, "emails"));
    querySnapshot.forEach((doc) => {
      emails = doc.data();
    });
    setAuthEmails([...Object.values(emails)]);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchData();
        setLoggedIn(user.email);
      } else {
        setLoggedIn(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loggedIn && authEmails.includes(loggedIn)) {
    return (
      <Container>
        <Wrapper className="font-hr ">
          <h2>Tour Price Calculator</h2>
          <div className="text-cont">
            <div className="w-100">
              <div className="label">Total Open Area{" (SqFt)"}</div>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                value={values.openArea}
                error={openAreaError}
                onChange={(event) => {
                  handleOpenAreaChange(event.target.value);
                }}
                helperText={
                  openAreaError
                    ? "Open Area and Built Area both cannot be zero"
                    : ""
                }
              />
            </div>
            <div className="w-100">
              <div className="label">Total Built Area{" (SqFt)"}</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                value={values.builtUpArea}
                error={builtAreaError}
                onChange={(event) => {
                  handleBuiltAreaChange(event.target.value);
                }}
                helperText={
                  builtAreaError
                    ? "Open Area and Built Area both cannot be zero"
                    : ""
                }
              />
            </div>
            <div className="w-100">
              <div className="label">Location</div>
              <Select
                value={values.location}
                label=""
                onChange={(event) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    location: event.target.value,
                  }));
                }}
                fullWidth
                size="small"
              >
                {ServiceLocations.map((location, index) => (
                  <MenuItem key={index} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="check-cont" style={{ alignItems: "start" }}>
            <div className="flex">
              <StyledCheckbox
                checked={premiumBooleans.infoCards}
                onChange={() => handlePremiumCheckbox("infoCards")}
              />
              <div className="w-100">
                <div className="flex">
                  <div className="label">Info Cards</div>
                  {premiumBooleans.infoCards &&
                  !values.premiumFeatures.infoCards ? (
                    <div style={{ color: "red" }}>*</div>
                  ) : (
                    ""
                  )}
                </div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!premiumBooleans.infoCards}
                  value={values.premiumFeatures.infoCards}
                  onChange={(event) => handleInputChange(event, "infoCards", 0)}
                />
              </div>
            </div>
            <div className="flex">
              <StyledCheckbox
                checked={premiumBooleans.photoAlbum}
                onChange={() => handlePremiumCheckbox("photoAlbum")}
              />
              <div className="w-100">
                <div className="flex">
                  <div className="label">PhotoAlbum </div>
                  {premiumBooleans.photoAlbum &&
                  !values.premiumFeatures.photoAlbum ? (
                    <div style={{ color: "red" }}>*</div>
                  ) : (
                    ""
                  )}
                </div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!premiumBooleans.photoAlbum}
                  value={values.premiumFeatures.photoAlbum}
                  onChange={(event) =>
                    handleInputChange(event, "photoAlbum", 0)
                  }
                />
              </div>
            </div>
            <div className="flex">
              <StyledCheckbox
                checked={premiumBooleans.onScreenVideo}
                onChange={() => handlePremiumCheckbox("onScreenVideo")}
              />
              <div className="w-100">
                <div className="flex">
                  <div className="label">OnScreen Video</div>{" "}
                  {premiumBooleans.onScreenVideo &&
                  !values.premiumFeatures.onScreenVideo ? (
                    <div style={{ color: "red" }}>*</div>
                  ) : (
                    ""
                  )}
                </div>

                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!premiumBooleans.onScreenVideo}
                  value={values.premiumFeatures.onScreenVideo}
                  onChange={(event) =>
                    handleInputChange(event, "onScreenVideo", 0)
                  }
                />
              </div>
            </div>
            <div className="flex">
              <StyledCheckbox
                checked={premiumBooleans.cgi}
                onChange={() => handlePremiumCheckbox("cgi")}
              />
              <div className="w-100">
                <div className="flex">
                  <div className="label">Special Effects</div>
                  {premiumBooleans.cgi && !values.premiumFeatures.cgi ? (
                    <div style={{ color: "red" }}>*</div>
                  ) : (
                    ""
                  )}
                </div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!premiumBooleans.cgi}
                  value={values.premiumFeatures.cgi}
                  onChange={(event) => handleInputChange(event, "cgi", 0)}
                />
              </div>
            </div>
            <div className="flex">
              <StyledCheckbox
                checked={premiumBooleans.panoVideo}
                onChange={() => handlePremiumCheckbox("panoVideo")}
              />
              <div className="w-100">
                <div className="flex">
                  <div className="label">360 degree Video</div>
                  {premiumBooleans.panoVideo &&
                  !values.premiumFeatures.panoVideo ? (
                    <div style={{ color: "red" }}>*</div>
                  ) : (
                    ""
                  )}
                </div>

                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!premiumBooleans.panoVideo}
                  value={values.premiumFeatures.panoVideo}
                  onChange={(event) => handleInputChange(event, "panoVideo", 0)}
                />
              </div>
            </div>
            <div className="flex">
              <StyledCheckbox
                checked={premiumBooleans.tourGuide}
                onChange={() => handlePremiumCheckbox("tourGuide")}
              />
              <div className="w-100">
                <div className="flex">
                  <div className="label">Tour Guide</div>
                  {premiumBooleans.tourGuide &&
                  !values.premiumFeatures.tourGuide ? (
                    <div style={{ color: "red" }}>*</div>
                  ) : (
                    ""
                  )}
                </div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!premiumBooleans.tourGuide}
                  value={values.premiumFeatures.tourGuide}
                  onChange={(event) => handleInputChange(event, "tourGuide", 0)}
                />
              </div>
            </div>
            <div className="flex">
              <Switch
                checked={values.popUpImagePriced}
                onChange={() => {
                  handleSwitches("popUpImagePriced");
                }}
              />
              <div className="label">Pop-Up Images {">"} 10</div>
            </div>
            <div className="flex">
              <Switch
                checked={values.dymanicData}
                onChange={() => {
                  handleSwitches("dymanicData");
                }}
              />
              <div className="label">Dynamic Data</div>
            </div>
            <div className="flex">
              <Switch
                checked={values.gst}
                onChange={() => {
                  handleSwitches("gst");
                }}
              />
              <div className="label">Include GST</div>
            </div>
          </div>
          <div className="check-cont" style={{ alignItems: "start" }}>
            <div>
              <div className="label">Custom Branding</div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {Object.keys(CustomBrandingOptions).map((value) => {
                  return (
                    <ListItem key={value} disablePadding>
                      <ListItemButton
                        onClick={(event) => {
                          handleBranding(event, value);
                        }}
                        dense
                      >
                        <ListItemIcon>
                          <StyledCheckbox
                            edge="start"
                            checked={values.branding[value]}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={CustomBrandingOptions[value]} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <div>
              <div className="label">Hosting Server</div>
              <RadioGroup row name="row-radio-buttons-group">
                <FormControlLabel
                  checked={values.truedHosting}
                  value={values.truedHosting}
                  control={<Radio />}
                  label="True-D"
                  onChange={() => {
                    setValues({ ...values, truedHosting: true });
                  }}
                />
                <FormControlLabel
                  value={!values.truedHosting}
                  checked={!values.truedHosting}
                  control={<Radio />}
                  label="Client"
                  onChange={() => {
                    setValues({ ...values, truedHosting: false });
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <div className="price-container">
            {click && <CalculateAmount data={values} />}
          </div>
          <div className="btn-container">
            <Button disabled={handleResetDisable()} onClick={handleReset}>
              Reset
            </Button>
            <Button
              disabled={handleDataValidation()}
              variant="contained"
              onClick={() => {
                handleCalculate();
              }}
            >
              Calculate
            </Button>
          </div>
        </Wrapper>
      </Container>
    );
  }

  return (
    <>
      <SignIn onClick={handleLogin}>
        <div>Sign in With Google</div>
        <img className="logo" src={googleLogo} alt="google" />
      </SignIn>
      {authError ? (
        <h3 style={{ textAlign: "center" }}>Not Authorized</h3>
      ) : null}
    </>
  );
};

export default Calculator;
