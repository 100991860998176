import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Breakpoints } from "../constants";
import emailjs from "@emailjs/browser";
import clientLogos from "../assets/images/client-logos.svg";
import Snackbar from "@mui/material/Snackbar";
import clientCube from "../assets/images/client-cube.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  /* padding: 0 100px; */
  align-items: center;
  gap: 120px;
  flex-wrap: wrap;
  .contact-container {
    max-width: 600px;
    width: 475px;
  }

  .client-container {
    position: relative;
    border-radius: 25px;
    border: 6px solid #fff;
    background: linear-gradient(180deg, #0037ff 0.6%, #28aae1 100%);
    box-shadow: -6px 6px 8px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;

    .client {
      aspect-ratio: 299/359;
      overflow: auto;
    }
    .cube {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100px;
      height: 100px;
    }
    .cube-1 {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 215px;
      height: 200px;
    }
  }
  @media (max-width: ${Breakpoints.MED}) {
    gap: 30px;
    .client-container {
      width: 90%;
    }
  }
  @media (max-width: ${Breakpoints.SMALL}) {
    gap: 30px;
    .contact-container {
      width: 80%;
    }
    .client-container {
      width: 90%;
      height: 400px;
    }
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .sub-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.25px;
    margin-bottom: 25px;
    text-align: left;
    @media only screen and (max-width: ${Breakpoints.MED}) {
      font-size: 20px;
    }
  }

  label {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-size: 14px;
  }
  .name,
  .email,
  .phone,
  .subject,
  .message {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
  }
  .message {
    height: 5rem;
    min-width: 100%;
    max-width: 100%;
    max-height: 100px;
    min-height: 100px;
  }
  .submit {
    background-color: #fff;
    color: #000;
    padding: 12px 40px;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
  }
  .submit:enabled {
    background: linear-gradient(90deg, #0037ff 0%, #28aae1 100%);
    color: #fff;
  }
  input:focus {
    outline: none !important;
    border-color: var(--primary);
  }
  textarea:focus {
    outline: none !important;
    border-color: var(--primary);
  }
  button:disabled {
    background-color: #fff;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  .label-cont {
    display: flex;
    align-items: center;
    gap: 2px;
    span {
      color: var(--primary);
    }
  }
`;

const StyledHeading = styled.div`
  font-size: 48px;
  font-weight: 700;
  border-bottom: 3px solid var(--primary);
  width: max-content;
  margin: auto;
  margin-bottom: 50px;

  @media only screen and (max-width: ${Breakpoints.MED}) {
    font-size: 30px;
  }
`;
const StyledMap = styled.div`
  font-size: 48px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  overflow: hidden;
`;

function Contact() {
  const form = useRef();
  const [isPhone, setIsPhone] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [isSubject, setIsSubject] = useState("");
  const [isMessage, setIsMessage] = useState("");
  const [isName, setIsName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    if ([isEmail, isPhone, isSubject, isName].every((el) => !!el)) {
      setDisabled(false);
    } else {
      if (disabled === false) {
        setDisabled(true);
      }
    }
  }, [isEmail, isPhone, isSubject, isMessage, isName]);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_KEY,
        process.env.REACT_APP_EMAIL_TEMPLATE_KEY,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          setIsEmail("");
          setIsPhone("");
          setIsMessage("");
          setIsSubject("");
          setIsName("");
          setNotify("Will get in touch soon");
        },
        (error) => {
          setIsEmail("");
          setIsPhone("");
          setIsMessage("");
          setIsSubject("");
          setIsName("");
          setNotify("Something went wrong");
        }
      );
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const allowedNumbers = ["6", "7", "8", "9"];
    if (inputValue.length === 1) {
      if (allowedNumbers.includes(inputValue)) {
        setIsPhone(inputValue);
      }
    } else if (
      (Number(inputValue) || inputValue.length === 0) &&
      inputValue.length < 11
    ) {
      setIsPhone(inputValue);
    }
  };
  return (
    <>
      <StyledHeading className="contact-head">Contact</StyledHeading>
      <Wrapper className="font-hr">
        <div className="contact-container">
          {/* <p className="heading">Book a demo</p> */}
          <div className="sub-heading">
            Get on a quick call with us to know how we can add value to your
            business with our services
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="label-cont">
              <label>Your Name</label>
              <span className="required">*</span>
            </div>
            <input
              name="isName"
              value={isName}
              onChange={(e) => {
                setIsName(e.target.value);
              }}
              type="text"
              id="name"
              className="name"
            />
            <div className="label-cont">
              <label>Email</label>
              <span className="required">*</span>
            </div>
            <input
              name="isEmail"
              value={isEmail}
              onChange={(e) => {
                setIsEmail(e.target.value);
              }}
              className="email"
              type="email"
              id="email"
            />
            <div className="label-cont">
              <label>Phone Number</label>
              <span className="required">*</span>
            </div>
            <input
              value={isPhone}
              onChange={handleChange}
              className="phone"
              type="text"
              id="phone"
              name="isPhone"
            />
            <div className="label-cont">
              <label>Subject</label>
              <span className="required">*</span>
            </div>
            <input
              value={isSubject}
              onChange={(e) => {
                setIsSubject(e.target.value);
              }}
              className="subject"
              type="text"
              id="subject"
              name="isSubject"
            />
            <div className="label-cont">
              <label>Message</label>
            </div>
            <textarea
              value={isMessage}
              onChange={(e) => {
                setIsMessage(e.target.value);
              }}
              className="message"
              id="subject"
              name="isMessage"
            ></textarea>
            <center>
              <button className="submit" type="submit" disabled={disabled}>
                Submit
              </button>
            </center>
          </form>
        </div>
        <div className="client-container">
          <img className="client" src={clientLogos} alt="clients" />
          <img className="cube" src={clientCube} alt="cube" />
          <img className="cube-1" src={clientCube} alt="cube" />
        </div>
        <Snackbar
          autoHideDuration={4000}
          open={!!notify}
          onClose={() => {
            setNotify(null);
          }}
          message={notify}
          sx={{
            "& .MuiSnackbarContent-root": {
              background: "var(--primary-gradient)",
            },
          }}
        />
      </Wrapper>
      <StyledMap className="iframe">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15388.450022885889!2d75.123251!3d15.3703921!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d134a7ab1939%3A0x647e65422e6189ab!2sTrue%20Dimensions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1681844095765!5m2!1sen!2sin"
          width="85%"
          height="350"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="title"
        ></iframe>
      </StyledMap>
    </>
  );
}

export default Contact;
