import React from "react";
import "./footer.css";
import FooterLogo from "../../assets/images/footerLogo.svg";
import Social2 from "../../assets/images/Instagram.svg";
import Social3 from "../../assets/images/LinkedIn.svg";
import Social4 from "../../assets/images/Youtube.svg";
import { trackEvent } from "../GoogleAnalyticsLable";

const Footer = () => {
  return (
    <>
      <div className="footer-main">
        <div className="section-1">
          <span>
            <img className="social-icons-img" src={FooterLogo} alt="True-D" />
          </span>
          <span>Your Gateway to Immersive Exploration!</span>
        </div>
        {/* <div className="section-2">
          <div className="our-services-sec">
            <p>Our Services</p>
            <span>Virtual Tours</span>
          </div>
          <div>
            <div className="our-services-sec">
              <p>Company</p>
              <span>About Us</span>
              <span>Careers</span>
            </div>
          </div>
        </div> */}
        <div className="section-3">
          <div className="address-sec">
            <p>Address</p>
            <span>
              True Dimensions Pvt Ltd, 1st Floor, RHK Building, KLE
              Technological University <br></br>( BVB Campus ), Vidyanagar,
              Hubballi, Karnataka, India - 580031
            </span>
          </div>
          <div>
            <div className="company-sec">
              <p>Contact</p>
              <span>For any queries please connect with us at</span>
              <span>+91-94828 48107, 87478 67871</span>
              <span>
                <u>hello@truedimensions.in</u>
              </span>
            </div>
          </div>
        </div>
        <div className="section-4">
          <p>Social</p>
          <div className="social-icons">
            <a
              onClick={() => trackEvent("Footer", "click", "Instagram")}
              href="https://www.instagram.com/vr.true.dimensions/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icons-img" src={Social2} alt="Instagram" />
            </a>
            <a
              onClick={() => trackEvent("Footer", "click", "LinkedIn")}
              href="https://www.linkedin.com/company/truedimensions/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icons-img" src={Social3} alt="LinkedIn" />
            </a>
            <a
              onClick={() => trackEvent("Footer", "click", "Youtube")}
              href="https://www.youtube.com/@true_dimensions"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icons-img" src={Social4} alt="Youtube" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
