import React from "react";
import "./totalDeliveries.css";
import clients from "../../assets/images/clients.svg";
import area from "../../assets/images/area.svg";
import tourscount from "../../assets/images/tourscount.svg";

const TotalDeliveries = () => {
  return (
    <>
      <div className="achievements font-hr">
        <div className="clients">
          <div>
            <p>60+</p>
          </div>
          <div className="info">
            <img src={clients} alt="Clients" />
            <span>Total Clients Served</span>
          </div>
        </div>
        <div className="tours">
          <div>
            <p>45+</p>
          </div>
          <div className="info">
            <img src={tourscount} alt="Tours" />
            <span>Total Virtual Tours Completed</span>
          </div>
        </div>
        <div className="area">
          <div>
            <p>5Lakh+ sqft</p>
          </div>
          <div className="info">
            <img src={area} alt="Area" />
            <span>Total Coverage of Area</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalDeliveries;
