import cubix from "./assets/images/cubix.png";
import ice from "./assets/images/ice.png";
import bliss from "./assets/images/biss.png";
import sagar from "./assets/images/sagar.png";
import fortune from "./assets/images/fortune.png";
import ocean from "./assets/images/ocean.png";
import laguna from "./assets/images/laguna.png";
import arthigamya from "./assets/images/arthigamya.png";
import kasarwadi from "./assets/images/kasarwadi.png";
import karatgi from "./assets/images/karatagi.png";
import karigar from "./assets/images/karigar.png";
import twacha from "./assets/images/twacha.png";
import arihant from "./assets/images/arihant.png";
import neo from "./assets/images/neo.png";
import water from "./assets/images/water.png";
import vihari from "./assets/images/vihari.png";
import ctie from "./assets/images/ctie.png";
import soniya from "./assets/images/soniya.png";
import bharat from "./assets/images/bharat.jpeg";
import gujarat from "./assets/images/gujarat.jpeg";
import yashwitha from "./assets/images/yashwitha.jpeg";
import mahadev from "./assets/images/mahadev.png";
import lintel from "./assets/images/lintel.png";
import wewia from "./assets/images/wewia.png";
import grtsquare from "./assets/images/grtsquare.png";
import tiffinhouse from "./assets/images/tiffinhouse.png";
import icentral from "./assets/images/icentral.png";
import vividIndia from "./assets/images/vivid.png"
import shyamTiles from "./assets/images/shyam.png"
import koliwadGodown from "./assets/images/koliwad-godown.png"
import koliwadSimpolo from "./assets/images/koliwad-simpolo.png"
import koliwadGranite from "./assets/images/koliwad-granite.png"
import koliwadMainOffice from "./assets/images/koliwad-mainoffice.png"
import koliwadDisplay from "./assets/images/koliwad-displaycenter.png"
import sudhannaMarriage from "./assets/images/sudhanna-marriage.png"
import sudhannaLodging from "./assets/images/sudhanna-lodging.png"
import sudhannaBar from "./assets/images/sudhanna-bar.png"
import sudhannaStore from "./assets/images/sudhanna-store.png"
import shivSagarUphar from "./assets/images/shivasagar.png"
import spaceXFitness from "./assets/images/spaceXFitness.png"
import omSaiEnterprises from "./assets/images/omSaiEnterprises.jpg"


export const Breakpoints = {
  SMALL: "500px",
  MED: "750px",
  P800: "800px",
};

export const Sectors = [
  "All",
  "Hotels",
  "Resorts",
  "Hospitals",
  "Showrooms",
  "Health & Fitness",
  "Education",
  "Industries",
  "Others",
];

export const Tours = [
  "all",
  {
    sector: "hotels",
    tours: [
      {
        projectName: "Cubix Hotel",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/cubix-hotel/",
        thumbnail: cubix,
      },
      {
        projectName: "Omatra The Bliss Hotel",
        location: "Hubballi",
        projectLink: "https://theblisshotels.in/bliss/index.htm",
        thumbnail: bliss,
      },
      {
        projectName: "Sudhanna Residency",
        location: "Karkala",
        projectLink: "https://truedimensions.tech/virtual-tour/sudhanna-residency-lodging/",
        thumbnail: sudhannaLodging,
      },
      {
        projectName: "Marriage Halls",
        location: "Karkala",
        projectLink: "https://truedimensions.tech/virtual-tour/sudhanna-residency-marriage-halls/",
        thumbnail: sudhannaMarriage,
      },
      {
        projectName: "Bar & Restaurant",
        location: "Karkala",
        projectLink: "https://truedimensions.tech/virtual-tour/sudhanna-residency-family-bar-and-restaurant/",
        thumbnail: sudhannaBar,
      },
      {
        projectName: "Shiv Sagar Uphar",
        location: "Dharwad",
        projectLink: "https://truedimensions.tech/virtual-tour/shiv-sagar-uphar/",
        thumbnail: shivSagarUphar,
      },
      {
        projectName: "Sagar Hotel",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/sagar-hotel/",
        thumbnail: sagar,
      },
      {
        projectName: "Ice Cube",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/cubix-hotel/",
        thumbnail: ice,
      },
      {
        projectName: "Hotel GRT Square",
        location: "Dharwad",
        projectLink: "https://truedimensions.tech/virtual-tour/hotel-grt-square/",
        thumbnail: grtsquare,
      },
      {
        projectName: "The Tiffin House",
        location: "Dharwad",
        projectLink: "https://truedimensions.tech/virtual-tour/tiffin-house/",
        thumbnail: tiffinhouse,
      },
    ],
  },
  {
    sector: "resorts",
    tours: [
      {
        projectName: "Fortune Woods",
        location: "Dandeli",
        projectLink: "https://beonthetrail.com/virtual-tour/fortune-wood-river-side/",
        thumbnail: fortune,
      },
      {
        projectName: "Kudle Ocean Front",
        location: "Gokarna",
        projectLink: "https://beonthetrail.com/virtual-tour/kudle-ocean-front/",
        thumbnail: ocean,
      },
      {
        projectName: "Laguna Resort",
        location: "Dandeli",
        projectLink: "https://beonthetrail.com/virtual-tour/laguna-river-resort/",
        thumbnail: laguna,
      },
      {
        projectName: "Arthigamya Resort",
        location: "Gokarna",
        projectLink: "https://beonthetrail.com/virtual-tour/arthigamya-resort-&-spa/",
        thumbnail: arthigamya,
      },
      {
        projectName: "Wild Kasarwadi",
        location: "Dandeli",
        projectLink: "https://beonthetrail.com/virtual-tour/wild-kasarawadi/",
        thumbnail: kasarwadi,
      },
      {
        projectName: "Water Activities",
        location: "Dandeli",
        projectLink: "https://beonthetrail.com/virtual-tour/water-activities/",
        thumbnail: water,
      },
    ],
  },

  {
    sector: "hospitals",
    tours: [
      {
        projectName: "Karatgi Super Speciality Hospital",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/karatgi-hospital/",
        thumbnail: karatgi,
      },
      {
        projectName: "Dr Karigar's Cardio Diabetes Clinic",
        location: "Hubballi",
        projectLink: "http://klectie.com/virtual/support/karigar-clinic/index.htm",
        thumbnail: karigar,
      },
      {
        projectName: "Twacha Skin Clinic",
        location: "Hubballi",
        projectLink: "https://virtualtour.twachahubli.com/",
        thumbnail: twacha,
      },
    ],
  },
  {
    sector: "showrooms",
    tours: [
      {
        projectName: "Koliwad - Display Center",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/koliwad/display-centre/",
        thumbnail: koliwadDisplay,
      },
      {
        projectName: "Koliwad - Head Office",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/koliwad/main-office/",
        thumbnail: koliwadMainOffice,
      },
      {
        projectName: "Koliwad - Granite",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/koliwad/granite-division/",
        thumbnail: koliwadGranite,
      },
      {
        projectName: "Koliwad - Simpolo",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/koliwad/simpolo-gallery/",
        thumbnail: koliwadSimpolo,
      },
      {
        projectName: "Koliwad - Godown",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/koliwad/godown/",
        thumbnail: koliwadGodown,
      },
      {
        projectName: "Lintel Building Solutions",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/lintel/",
        thumbnail: lintel,
      },
      {
        projectName: "WeWia",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/wewia-demo/",
        thumbnail: wewia,
      },
      {
        projectName: "iCentral",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/icentral-hubballi/",
        thumbnail: icentral,
      },
      {
        projectName: "Bharat Marbles",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/bharat-marbles/",
        thumbnail: bharat,
      },
      {
        projectName: "Yashwitha Ceramics",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/yashwitha-ceramics/",
        thumbnail: yashwitha,
      },
      {
        projectName: "House Of JOHNSON",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/vivid-india/",
        thumbnail: vividIndia,
      },
      {
        projectName: "Mahadev Marbles & Granites",
        location: "Belgaum",
        projectLink: "https://truedimensions.tech/virtual-tour/mahadev-marbles-and-granites/",
        thumbnail: mahadev,
      },
      {
        projectName: "Shyam Tiles & Granites",
        location: "Belgaum",
        projectLink: "https://truedimensions.tech/virtual-tour/shyam-tiles/",
        thumbnail: shyamTiles,
      },
      {
        projectName: "Arihant Enterprise",
        location: "Dharwad",
        projectLink: "https://truedimensions.tech/virtual-tour/arihant-enterprise/",
        thumbnail: arihant,
      },
      {
        projectName: "Gujarat Ceramics",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/gujarat-ceramic/",
        thumbnail: gujarat,
      },
    ],
  },
  {
    sector: "Health & Fitness",
    tours: [
      {
        projectName: "Space X Fitness",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/spacexfitness/",
        thumbnail: spaceXFitness,
      },
    ],
  },
  {
    sector: "education",
    tours: [
      {
        projectName: "KLE CTIE",
        location: "Hubballi",
        projectLink: "https://www.klectie.com/virtual/index.htm",
        thumbnail: ctie,
      },
      {
        projectName: "Soniya Pharmacy College",
        location: "Dharwad",
        projectLink: "https://www.soniyapharmacy.org/virtual/virtual-tour/soniya-college/index.htm",
        thumbnail: soniya,
      },
    ],
  },
  {
    sector: "industries",
    tours: [
      {
        projectName: "Neo Valves Industries",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/neovalves-industries/",
        thumbnail: neo,
      },
    ],
  },

  {
    sector: "others",
    tours: [
      {
        projectName: "Vihari Studio",
        location: "Hubballi",
        projectLink: "https://truedimensions.tech/virtual-tour/vihari-click-clothes/",
        thumbnail: vihari,
      },
      {
        projectName: "Kemmanja Super Market",
        location: "Karkala",
        projectLink: "https://truedimensions.tech/virtual-tour/kemmanja-super-market/",
        thumbnail: sudhannaStore,
      },
      {
        projectName: "Om Sai Enterprises",
        location: "Dharwad",
        projectLink: "https://truedimensions.tech/virtual-tour/sai-enterprise/",
        thumbnail: omSaiEnterprises,
      },
    ],
  },
];

export const CustomBrandingOptions = {
  brandColor: "Brand Color Palette",
  customHotspotPriced: "Custom Hotspots",
  ambientSounds: "Ambient Sounds",
  customLoading: "Custom Loading Screen",
};

export const priceParams = {
  openArea: "",
  builtUpArea: "",
  location: "",
  premiumFeatures: {
    infoCards: "",
    photoAlbum: "",
    onScreenVideo: "",
    cgi: "",
    panoVideo: "",
    tourGuide: "",
  },
  popUpImagePriced: false,
  dymanicData: false,
  branding: {
    brandColor: false,
    customHotspotPriced: false,
    ambientSounds: false,
    customLoading: false,
  },
  truedHosting: true,
  gst: false,
};

export const ServiceLocations = [
  "Karnataka (Hubli-Dharwad)",
  "Karnataka (Outside Hubli-Dharwad)",
  "Telangana",
  "Andhara Pradesh",
  "Tamil Nadu",
  "kerala",
  "Goa",
  "Maharashtra",
  "Other",
];
