import React, { useState, useEffect } from "react";
import "./hero.css";
import Hero1 from "../../assets/images/Hero1.svg";
import Hero2 from "../../assets/images/Hero2.svg";

const Hero = () => {
  const images = [Hero1, Hero2];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div style={{ height: "80px" }}></div>
      <div className="hero-main font-hr">
        <div className="images-main">
          <div className="hero-images">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Hero-${index + 1}`}
                style={{ opacity: index === currentIndex ? 1 : 0 }}
              />
            ))}
          </div>
        </div>
        <div className="hero-content hero-content-responsive">
          <div className="welcome welcome-responsive">Welcome to</div>
          <div className="true-dimension">
            <span className="true">TRUE</span>
            <span className="dimensions">DIMENSIONS</span>
          </div>

          <div className="true-dimension-responsive">
            <div className="true-responsive">TRUE</div>
            <div className="dimensions-responsive">DIMENSIONS</div>
          </div>
          <div className="transform">
            Your Gateway to Immersive Exploration!
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
