import logo from "../assets/true-logo.svg";
import styled from "@emotion/styled";
import { Breakpoints } from "../constants";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "./GoogleAnalyticsLable";
import { Link } from "react-scroll";

const HeaderWrapper = styled.div`
  z-index: 99999;
  padding: 16px 10%;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 55, 255, 0.5);
  backdrop-filter: blur(100px);
  position: fixed;
  top: 0;
  left: 0;
  width: -webkit-fill-available;

  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    cursor: pointer;
  }
  .nav-container {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    font-weight: 700;
    cursor: pointer;
    .fromLeft:after {
      display: block;
      content: "";
      border-bottom: 3px solid var(--primary);
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
    .fromLeft:hover:after {
      transform: scaleX(1);
    }
    .fromLeft:after {
      transform-origin: 0% 50%;
    }

    @media only screen and (max-width: ${Breakpoints.MED}) {
      display: none;
    }
  }

  @keyframes expandBorder {
    0% {
      border-bottom-height: 0;
    }
    100% {
      border-bottom-height: 100%;
    }
  }
  .hamburger {
    flex-direction: column;
    width: 25px;
    gap: 5px;
    color: #000;
    display: none;
    @media only screen and (max-width: ${Breakpoints.MED}) {
      display: flex;
    }
  }
  .hamburger-line {
    background-color: #000;
    height: 2px;
  }
  .test {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    font-weight: 700;

    border: 0;
    .tab-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      .fromLeft:after {
        display: block;
        content: "";
        border-bottom: 3px solid var(--primary);
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
      .fromLeft:hover:after {
        transform: scaleX(1);
      }
      .fromLeft:after {
        transform-origin: 0% 50%;
      }
    }
    .helper {
      height: 30px;
    }
  }
  .visible {
    max-height: 400px;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
    position: relative;
  }
`;

const HamburgerLine = styled.span`
  width: 30px;
  height: 3px;
  background: var(--primary-gradient);
  transition: all 0.3s;
  &:first-child {
    transform: ${(props) => (props.toggle ? "rotate(-45deg)" : "none")};
    position: ${(props) => (props.toggle ? "absolute" : "none")};
  }
  &:last-child {
    transform: ${(props) => (props.toggle ? "rotate(45deg)" : "none")};
  }

  &:nth-child(2) {
    display: ${(props) => (props.toggle ? "none" : "block")};
  }
`;

const Helper = styled.div`
  height: 80px;
`;

const sections = [
  { name: "Home", id: "home", path: "#home", offset: 0 },
  { name: "Our Service", id: "services", path: "#services", offset: -70 },
  { name: "Our Work", id: "our-work", path: "#our-work", offset: -90 },
  { name: "Contact", id: "contact", path: "#contact", offset: -80 },
  { name: "About Us", id: "about", path: "#about", offset: -100 },
];

const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSectionClick = (event, sectionPath, sectionId) => {
    event.preventDefault();
    navigate(sectionPath);
    trackEvent("Header", "click", sectionId);
    setOpen(false);
  };

  return (
    <Fragment>
      <HeaderWrapper>
        <div className="main-container">
          <img src={logo} alt="logo" height="40px" width="115px" />
          <div className="nav-container font-hr">
            {sections.map((section) => (
              <Link
                to={section.id}
                smooth={true}
                spy={true}
                offset={section.offset}
                duration={500}
                activeClass="el-active"
              >
                <a
                  href={`#${section.id}`}
                  onClick={(event) =>
                    handleSectionClick(event, section.path, section.id)
                  }
                  className="fromLeft"
                  key={section.id}
                >
                  {section.name}
                </a>
              </Link>
            ))}
          </div>
          <HamburgerIcon
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            <HamburgerLine className="line-1" toggle={open} />
            <HamburgerLine className="line-2" toggle={open} />
            <HamburgerLine className="line-3" toggle={open} />
          </HamburgerIcon>
        </div>
        <div className={`test ${open ? "visible" : ""}`}>
          <div className="helper"></div>
          <div className="tab-container">
            {sections.map((section) => (
              <Link
                to={section.id}
                smooth={true}
                spy={false}
                offset={section.offset}
                duration={500}
              >
                <a
                  href={`#${section.id}`}
                  onClick={(event) =>
                    handleSectionClick(event, section.path, section.id)
                  }
                  className="fromLeft"
                  key={section.id}
                >
                  {section.name}
                </a>
              </Link>
            ))}
          </div>
        </div>
      </HeaderWrapper>
      <Helper />
    </Fragment>
  );
};

export default Header;
